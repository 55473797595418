.series-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
  font-size: 24px!important;
  padding:5rem 100px;
  background-color: #fff;
  iframe{
    width: 80%!important;
    height: 500px!important;
    margin: 0 auto;
  }
  h2{
    font-size: 64px;
    font-weight: 700;
  }
  td{
    font-size: 24px!important;
  }
  .series_img{
    width: 100%;
    img{
      width: 100%;
    }
  }
  a{
    color: #CA4625;
  }
  .series-grid {
    // display: grid;
    // grid-template-columns: 50% 50%;
    width: 100%;
    margin: 0rem auto;
    // gap: 3rem;
    justify-content: center;
    p,td,tr,th,small{
      margin: 1rem 0;
      color: black !important;
    }
    .series-text {
      p {
        font-size: 18px;
        color: black !important;
        font-weight: 500;
      }
    }
    .series-image {
      img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 25px;
      }
    }
  }
}






