

.cardTile{
  // border: 1px solid #636e75;
  margin-top: 5px;
  border-radius: 10px;
  box-shadow: 1px 2px #5664d2;;
  
  
   
}
.imgCard{
  margin: 2px!important;
  border:1px solid #121212!important;
  position: relative;
  padding: 0!important; 
}
.upImages{
 width: 100%;
 height: 200px;

 
}
.title{
 text-align:center ;
}

.copyUrl{
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: inline-block;
  width: fit-content!important;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 16px;
  text-align: center;
  opacity: 0.5;
  &:hover{
    color: #121212;
    opacity: 1;
    background-color: #ffffffd4;
  }
}
.cLogo{
  width: 15px;
  height:15px
}