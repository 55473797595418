.cursor-pointer {
 cursor: pointer;
}
.bg-hover-effect{
  cursor: pointer;
  border-radius: 5px;
}
.bg-hover-effect:hover{
  background-color: #f5f5f5;
}

.spinner-container{
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000030;
  z-index: 100;
  border-radius: 10px;
}

.spinner-dashboard{
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-15{
  width: 15%;
}
.react-tel-input .form-control{
  width: 100%;
}

.w-100{
  width: 100%;
}

@mixin switch($res: 'sm') {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == 'md' {
      $index: 2rem;
      $mainVal: 1.5rem;
  } @else if $res == 'lg' {
      $index: 3rem;
      $mainVal: 2rem;
  } @else if $res == 'xl' {
      $index: 4rem;
      $mainVal: 2.5rem;
  }

  .custom-control-label {
      padding-left: #{$index};
      padding-bottom: #{$mainVal};
  }

  .custom-control-label::before {
      height: $mainVal;
      width: calc(#{$index} + 0.75rem);
      border-radius: $mainVal * 2;
  }

  .custom-control-label::after {
      width: calc(#{$mainVal} - 4px);
      height: calc(#{$mainVal} - 4px);
      border-radius: calc(#{$index} - (#{$mainVal} / 2));
  }

  .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(calc(#{$mainVal} - 0.25rem));
  }
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl
.custom-switch.custom-switch-sm {
  @include switch();
}

.custom-switch.custom-switch-md {
  @include switch('md');
}

.custom-switch.custom-switch-lg {
  @include switch('lg');
}

.custom-switch.custom-switch-xl {
  @include switch('xl');
}